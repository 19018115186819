import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FaExclamation } from "react-icons/fa6";
import "@splidejs/splide/dist/css/splide.min.css";
import ClockTimer from "./clock";
import Congratulation from "./congratulation";
import ExclamationModal from "./exclamationModal";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import ZoomableImage from "./ImageZoom";
import { useMediaQuery } from "react-responsive";
import { Timestamp } from "@firebase/firestore";
import { createNewUserEntry } from "../../firebase/store";
import { ref, getDownloadURL, getStorage } from "firebase/storage";
import { Grid } from "react-loader-spinner";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

const Play = () => {
  const splideRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const [quizStart, setQuizStart] = useState(false);
  const [showQus, setShowQus] = useState(false);
  const [questions, setQuestions] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [imgSrc, setImgSrc] = useState("");

  const [count, setCount] = useState(0);
  const [running, setRunning] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [animate, setAnimate] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);
  const divRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [givenAnswers, setGivenAnswers] = useState([]); // Initialize as an empty array of arrays
  const [updateComplete, setUpdateComplete] = useState(false);
  const [currentUserAnswer, setCurrentUserAnswer] = useState("");

  const baseUrl = "https://d1nxxhrpkk2ck5.cloudfront.net";

  const appendAnswersForQuestion = (index, answer) => {
    setGivenAnswers((prevAnswers) => {
      setCurrentUserAnswer(answer);
      const newAnswers = [...prevAnswers];
      if (!newAnswers[index]) {
        newAnswers[index] = [];
      }
      newAnswers[index] = [...newAnswers[index], answer];

      localStorage.setItem("userAnswers", JSON.stringify(newAnswers));
      storUserState();
      return newAnswers;
    });

    // Set updateComplete to true
    setUpdateComplete(true);
  };

  useEffect(() => {
    if (updateComplete) {
      singleQuestionAnswerCheck();
      // Reset updateComplete to false after the check
      setUpdateComplete(false);
    }
  }, [updateComplete]);

  // Function to check if an answer is in the array at a specific index
  const isAnswerInArrayAtIndex = (index, answer) => {
    return givenAnswers[index]?.includes(answer);
  };

  // Function to reset the givenAnswers array to an empty array
  const resetGivenAnswers = () => {
    setGivenAnswers([]);
    localStorage.setItem("userAnswers", JSON.stringify([]));
  };

  // Function to get the answers for a specific question index
  const getAnswersForQuestion = (index) => {
    return givenAnswers[index] || [];
  };

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  // Function to update the width
  const updateWidth = () => {
    if (divRef.current) {
      setTimeout(() => {
        console.log("Current Width:", divRef.current.offsetWidth);
        setWidth(divRef.current.offsetWidth);
      }, 200); // Adjust the delay as needed
    }
  };

  const debouncedUpdateWidth = debounce(updateWidth, 200);

  useLayoutEffect(() => {
    updateWidth();

    window.addEventListener("resize", debouncedUpdateWidth);
    return () => {
      window.removeEventListener("resize", debouncedUpdateWidth);
    };
  }, []);

  const transformWrapperRef = useRef(null);

  useEffect(() => {
    const handleOrientationChange = () => {
      // Recenter the image
      if (transformWrapperRef.current) {
        const wrapper = transformWrapperRef.current;
        wrapper.centerView(1);
      }
    };

    // Add event listener for orientation change
    window.addEventListener("resize", handleOrientationChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isTimerStarted) {
      timer = setTimeout(() => {
        pictureSeen(true);
      }, 30000); // 20000 milliseconds = 20 seconds
    }

    // Cleanup function to clear the timer
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isTimerStarted]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, "0");
        let dateString = `${yyyy}-${mm}-${dd}`;
        const isDebugMode = process.env.REACT_APP_DEBUG === "true";
        if (isDebugMode) {
          let targetDate = localStorage.getItem("targetDate");
          if (!targetDate) {
            targetDate = "2024-07-25";
            localStorage.setItem("targetDate", targetDate);
          }
          dateString = targetDate;
        }

        const fileUrl = `${baseUrl}/files/${dateString}.json`;
        const imageUrl = `${baseUrl}/images/${dateString}.png`;

        setImgSrc(imageUrl);

        const response = await fetch(fileUrl);
        if (!response.ok) {
          logEvent(analytics, "error", {
            status: response.status,
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        let UserGamePlayObject = localStorage.getItem("UserGamePlay");
        UserGamePlayObject = JSON.parse(UserGamePlayObject);

        let userAnswers = localStorage.getItem("userAnswers");
        userAnswers = JSON.parse(userAnswers);
        if (userAnswers) {
          setGivenAnswers(userAnswers);
        }

        if (UserGamePlayObject) {
          if (UserGamePlayObject.status === "COMPLETED") {
            setShowResult(true);
            setQuestions(data);
          } else {
            if (!showQus) {
              if (!UserGamePlayObject.hasSeenPicture) {
                setQuestions(data);
                setQuestionIndex(0);
                handleCountdown(data?.durationInSecs);
                setQuizStart(true);
                pictureSeen(false);
                const timet = setTimeout(() => {
                  updateWidth();
                }, 200);
                return () => clearTimeout(timet);
              } else {
                setQuestions(data);

                setQuestionIndex(UserGamePlayObject.currentQuestionIndex);
                setQuizStart(true);
                const timet = setTimeout(() => {
                  updateWidth();
                }, 200);
                return () => clearTimeout(timet);
              }
            }
          }
        } else {
          //brand new UserObject is null
          if (!showQus) {
            let UserGamePlayObject = {
              gameState: [],
              hasSeenPicture: false,
              currentQuestionIndex: 0,
              currentScore: 0,
              status: "IN_PROGRESS",
              lastCompleted: 0,
              lastPlayed: 0,
              gamesPlayed: 0,
              currentStreak: 0,
              isOnStreak: false,
              AvgScorePercentage: 0,
              puzzle_id: data.id,
              created_at: Date.now(),
            };

            // Get currentStreak from localStorage
            const storedCurrentStreak = localStorage.getItem("currentStreak");
            const storedGamesPlayed = localStorage.getItem("gamesPlayed");

            // Parse the stored value (localStorage stores everything as string, so parseInt is needed)
            const parsedCurrentStreak = parseInt(storedCurrentStreak, 10);
            const parsedGamesPlayed = parseInt(storedGamesPlayed, 10);

            // Check if the stored value is a valid number and greater than 0
            if (!isNaN(parsedCurrentStreak) && parsedCurrentStreak > 0) {
              UserGamePlayObject.currentStreak = parsedCurrentStreak;
            } else {
              UserGamePlayObject.currentStreak = 0; // Set to zero if the stored value is not valid or is zero
            }

            // Check if the stored value is a valid number and greater than 0
            if (!isNaN(parsedGamesPlayed) && parsedGamesPlayed > 0) {
              UserGamePlayObject.gamesPlayed = parsedGamesPlayed;
            } else {
              UserGamePlayObject.gamesPlayed = 0; // Set to zero if the stored value is not valid or is zero
            }

            localStorage.setItem(
              "UserGamePlay",
              JSON.stringify(UserGamePlayObject)
            );
            createNewUserEntry(UserGamePlayObject); // could get rid of this create

            setQuestions(data);
            setQuestionIndex(0);
            handleCountdown(data?.durationInSecs);
            setQuizStart(true);
            pictureSeen(false);
            const timet = setTimeout(() => {
              updateWidth(); //what's the point of this one?? update width after image is loaded?
            }, 200);

            return () => clearTimeout(timet);
          }
        }
      } catch (error) {
        console.error("Could not fetch the data", error);
      }
    };

    fetchData();

    // console.log("Component mounted");
    // return () => {
    //   console.log("Component unmounted");
    // };
  }, []);

  const handleCountdown = (seconds) => {
    setCount(seconds);
    setRunning(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsTimerStarted(true); // Start the timer when modal is closed
  };

  const goToRight = () => {
    splideRef.current.splide.go(">");
  };

  const pictureSeen = (skip) => {
    if (!skip) return;
    let userObject = JSON.parse(localStorage.getItem("UserGamePlay"));
    // const { game_data: { game, stats }, puzzle_id, timestamp } = userObject;
    userObject["hasSeenPicture"] = true;

    localStorage.setItem("UserGamePlay", JSON.stringify(userObject));
    createNewUserEntry(userObject);
  };

  if (quizStart) {
    if (count === 0) {
      setQuizStart(false);
      setShowQus(true);
    }
  }

  const storUserState = () => {
    const userAnswers = JSON.parse(localStorage.getItem("userAnswers"));
    const userObject = JSON.parse(localStorage.getItem("UserGamePlay"));

    if (!userAnswers || !userObject) {
      console.error(
        "userAnswers or userObject is not available in localStorage"
      );
      return;
    }

    // Update gameState with userAnswers
    userObject.gameState = userAnswers;

    localStorage.setItem("UserGamePlay", JSON.stringify(userObject));
  };

  const animation = () => {
    setTimeout(() => {
      setAnimate(false);
    }, 1800);
  };

  const changeQuestion = () => {
    setTimeout(() => {
      setAnimate(true);
      animation();
      goToRight();
      setQuestionIndex(questionIndex + 1);
    }, 1200);
  };

  const finalUpdate = () => {
    setTimeout(() => {
      resetGivenAnswers();
      setShowResult(true);
      setShowQus(false);
    }, 1000);
  };

  const getMessage = (currentTryNumber) => {
    const messages = ["Amazing 😄", "Good Job 👍", "Phew! 😅"];
    return messages[currentTryNumber] || "";
  };

  const singleQuestionAnswerCheck = () => {
    //NEEDS OPTIMIZATION

    let UserObject = localStorage.getItem("UserGamePlay");
    UserObject = JSON.parse(UserObject);

    // const { game_data, puzzle_id, timestamp } = UserObject;
    const {
      gameState,
      hasSeenPicture,
      currentQuestionIndex,
      currentScore,
      puzzle_id,
    } = UserObject;
    // const { stats } = game_data;

    let newCurrentScore; // TODO: Need to take care of score calculations and rules

    switch (gameState[questionIndex]?.length) {
      case 1:
        newCurrentScore = currentScore + 3;
        break;
      case 2:
        newCurrentScore = currentScore + 2;
        break;
      case 3:
        newCurrentScore = currentScore + 1;
        break;
      default:
        newCurrentScore = currentScore + 3;
    }

    if (questions?.game?.questions.length === questionIndex + 1) {
      // IF THIS IS THE LAST QUESTION TO BE ANSWERED
      if (
        currentUserAnswer ===
        `${questions?.game?.questions[questionIndex]?.corr_ans}`
      ) {
        // if user picks the right answer

        UserObject.currentQuestionIndex = 0;
        UserObject.currentScore = newCurrentScore;
        UserObject.status = "COMPLETED";
        UserObject.lastPlayed = Timestamp.fromDate(new Date());
        UserObject.lastCompleted = Timestamp.fromDate(new Date());
        UserObject.gamesPlayed = UserObject.gamesPlayed + 1;
        UserObject.currentStreak = UserObject.currentStreak + 1;
        UserObject.isOnStreak = true;
        UserObject.AvgScorePercentage = Math.round((newCurrentScore / 9) * 100);

        localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
        createNewUserEntry(UserObject);

        finalUpdate();

        const message = getMessage(
          (gameState[questionIndex] && gameState[questionIndex].length - 1) || 0
        );
        toast(message, {
          duration: 1000,
          style: {
            borderRadius: "99px",
            background: "#06BF66",
            color: "#fff",
          },
        });
      } else {
        // if we are on last question but user picks a wrong answer but still there are more chances to guess

        if (!gameState[questionIndex] || gameState[questionIndex]?.length < 3) {
          UserObject.status = "IN_PROGRESS";
          UserObject.currentScore = currentScore;
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);
        } else {
          // if all trials are gone

          UserObject.currentQuestionIndex = 0;
          UserObject.currentScore = currentScore;
          UserObject.status = "COMPLETED";
          UserObject.lastPlayed = Timestamp.fromDate(new Date());
          UserObject.lastCompleted = Timestamp.fromDate(new Date());
          UserObject.gamesPlayed = UserObject.gamesPlayed + 1;
          // UserObject.currentStreak = 0;
          if (currentScore > 0) {
            UserObject.isOnStreak = true;
            UserObject.currentStreak = UserObject.currentStreak + 1;
          } else {
            UserObject.isOnStreak = false;
            UserObject.currentStreak = 0;
          }

          UserObject.AvgScorePercentage = Math.round(
            (newCurrentScore / 9) * 100
          );

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);

          finalUpdate();
          toast("Try again next time", {
            duration: 1000,
            icon: "😢",
            style: {
              borderRadius: "99px",
              background: "#CD0000",
              color: "#fff",
            },
          });
        }
      }
    } else {
      // IF WE ARE NOT ON THE LAST QUESTION
      if (
        currentUserAnswer ===
        `${questions?.game?.questions[questionIndex]?.corr_ans}`
      ) {
        // if user picks the right answer
        UserObject.currentQuestionIndex = currentQuestionIndex + 1;
        UserObject.currentScore = newCurrentScore;
        UserObject.status = "IN_PROGRESS";
        UserObject.lastPlayed = Timestamp.fromDate(new Date());

        localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
        createNewUserEntry(UserObject);

        // setShowRightAns(true) // probably useless
        changeQuestion();

        const message = getMessage(
          (gameState[questionIndex] && gameState[questionIndex].length - 1) || 0
        );
        toast(message, {
          duration: 1000,
          style: {
            borderRadius: "99px",
            background: "#06BF66",
            color: "#fff",
          },
        });
      } else {
        // If user picks wrong answer but still more chances on the same question
        if (!gameState[questionIndex] || gameState[questionIndex]?.length < 3) {
          UserObject.status = "IN_PROGRESS";
          UserObject.currentScore = currentScore;
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);
        } else {
          // if all trials are gone

          UserObject.currentQuestionIndex = currentQuestionIndex + 1;
          UserObject.currentScore = currentScore;
          UserObject.status = "IN_PROGRESS";
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);

          changeQuestion();
          toast("Try again next time", {
            duration: 1000,
            icon: "😢",
            style: {
              borderRadius: "99px",
              background: "#CD0000",
              color: "#fff",
            },
          });
        }
      }
    }
  };

  //#######################################################
  //#######################################################
  //Display code
  //#######################################################
  //#######################################################

  return (
    <div>
      <div className="main-app-div">
        {quizStart && (
          <>
            {imgSrc ? (
              <div>
                {!isMobile ? (
                  <div className="home-main-div" style={{ maxWidth: "auto" }}>
                    <FaExclamation
                      className={"exclamation-icon"}
                      onClick={() => setIsModalOpen(true)}
                    />
                    <ExclamationModal
                      isOpen={isModalOpen}
                      onClose={handleModalClose}
                    />

                    {isTimerStarted && (
                      <ClockTimer
                        count={count}
                        running={running}
                        setCount={setCount}
                        isMobile={isMobile}
                      />
                    )}

                    <button
                      className={"skip-btn"}
                      onClick={() => {
                        logEvent(analytics, "skip", {
                          count: count,
                        });
                        setCount(0);
                        pictureSeen(true);
                      }}
                    >
                      Skip
                    </button>
                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        height: "100vh",
                        padding: "1.5%",
                      }}
                      ref={divRef}
                    >
                      <ZoomableImage
                        src={imgSrc}
                        // src="https://wallpapercave.com/wp/wp2245817.jpg"
                        // src='https://i.ibb.co/1dHLDYP/img1080x608.png'
                        // src='https://i.ibb.co/pQTG1qy/img1080x1350.png'
                        imgW="100%"
                        imgH="100vh"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <FaExclamation
                      className={"exclamation-icon-mobile"}
                      onClick={() => setIsModalOpen(true)}
                    />
                    <ExclamationModal
                      isOpen={isModalOpen}
                      onClose={handleModalClose}
                    />

                    {isTimerStarted && (
                      <ClockTimer
                        count={count}
                        running={running}
                        setCount={setCount}
                        isMobile={isMobile}
                      />
                    )}

                    <button
                      className={"skip-btn-mobile"}
                      onClick={() => {
                        setCount(0);
                        pictureSeen(true);
                      }}
                    >
                      Skip
                    </button>

                    <div
                      style={{
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        // minHeight: "100vh",
                        position: "fixed",
                        width: "98%",
                        height: "98%",
                        border: "1px solid rgb(0, 0, 0)",
                        borderRadius: "20px",
                        overflow: "clip",
                        top: "1%",
                        // bottom: "1%",
                        left: "1%",
                        // margin: "6px",
                      }}
                    >
                      <TransformWrapper centerOnInit ref={transformWrapperRef}>
                        <TransformComponent
                          wrapperStyle={{
                            width: "100%",
                            // height: "97vh",
                            background: "rgb(247 243 243 / 57%)",
                            height: "100%",
                          }}
                        >
                          <img
                            // src="https://i.pinimg.com/originals/8a/38/c3/8a38c31c64179952e4afbd511674f9dc.jpg"
                            // src="https://firebasestorage.googleapis.com/v0/b/reactmemorygame-f91e5.appspot.com/o/images%2F2024-07-25.png?alt=media&token=f7ae0286-13b1-427e-a7ea-a35c8dfdc1d8"
                            src={imgSrc}
                            alt="test"
                            width="100%"
                            height="100%"
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </div>

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                      }}
                    >
                      <img
                        style={{ maxHeight: "100vh" }}
                        src={imgSrc}
                        // src='https://i.ibb.co/Y7bBsw6/img1080x1920.png'
                        // src='https://i.ibb.co/1dHLDYP/img1080x608.png'
                        // src='https://i.ibb.co/pQTG1qy/img1080x1350.png'
                        alt="test"
                      />
                    </div> */}
                  </div>
                )}
              </div>
            ) : (
              <div className="spinner-container">
                <div className="spinner">
                  <Grid
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {showQus && (
        <div className="question-div">
          <p className="question-text">Q{questionIndex + 1}.</p>

          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1.2 }}
          >
            <p className="question-full-text">
              {questions?.game?.questions[questionIndex].question}
            </p>
          </motion.div>

          <Splide
            options={{
              type: "loop",
              arrows: false,
              drag: false,
              wheel: false,
              keyboard: false,
              pagination: false,
              speed: "1100",
            }}
            ref={splideRef}
          >
            <SplideSlide>
              {questions?.game?.questions[questionIndex].answers.map(
                (answer, index) => (
                  <motion.div
                    key={index}
                    animate={
                      animate
                        ? { x: -600, transition: { duration: index * 0.5 } }
                        : { x: 0, transition: { duration: 0 } }
                    }
                  >
                    <div key={answer} className="mr-2">
                      {givenAnswers[questionIndex]?.length === 0 ||
                      !givenAnswers[questionIndex] ? (
                        <div
                          className="option-div"
                          onClick={() => {
                            appendAnswersForQuestion(questionIndex, answer);
                            // singleQuestionAnswerCheck(answer);
                          }}
                        >
                          <p className="font-bold text-[18px]">
                            <span className="mr-2">
                              {
                                ["A. ", "B. ", "C. ", "D. ", "E. ", "F. "][
                                  index
                                ]
                              }
                            </span>
                            {"  "}
                            {answer}
                          </p>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            if (
                              !givenAnswers[questionIndex].includes(answer) &&
                              givenAnswers[questionIndex].length < 3
                            ) {
                              appendAnswersForQuestion(questionIndex, answer);
                              //   singleQuestionAnswerCheck(answer);
                            }
                          }}
                          className={`option-div single-option ${
                            questions?.game?.questions[questionIndex]
                              ?.corr_ans === answer
                              ? givenAnswers[questionIndex]?.includes(answer)
                                ? "option-green"
                                : "option-black"
                              : givenAnswers[questionIndex]?.includes(answer)
                              ? "option-red"
                              : "option-black"
                          }`}
                        >
                          <p className="font-bold text-[18px]">
                            <span className="mr-2">
                              {
                                ["A. ", "B. ", "C. ", "D. ", "E. ", "F. "][
                                  index
                                ]
                              }
                            </span>
                            {"  "}
                            {answer}
                          </p>
                        </div>
                      )}
                    </div>
                  </motion.div>
                )
              )}
            </SplideSlide>
          </Splide>
        </div>
      )}
      {showResult && <Congratulation questions={questions?.game?.questions} />}
      <Toaster />
    </div>
  );
};

export default Play;
