import React, { useEffect, useState } from "react";
import { HiShare } from "react-icons/hi";
import graph from "../../media/graph bars.png";
import aero from "../../media/arrow.png";
import CongratulationModal from "./congratulationModal";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAuth } from "../../contexts/authContext";
import choicesImage from "./../../media/choices_smaller.png";
import { isMobile } from "react-device-detect";
import rotateImage from "./../../media/rotate.png"; // Add the rotate image

Modal.setAppElement("#root");
const analytics = getAnalytics();

const Congratulation = ({ questions }) => {
  const [result, setResult] = useState();
  const [showCongratulationModal, setCongratulationModal] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false); // Track landscape mode
  const [passedQuestions, setPassedQuestions] = useState(questions);
  const [aggregatedResults, setAggregatedResults] = useState();
  const { userLoggedIn } = useAuth();

  useEffect(() => {
    // Check for landscape mode
    const checkOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    checkOrientation(); // Initial check
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  useEffect(() => {
    let UserObject = localStorage.getItem("UserGamePlay");
    UserObject = JSON.parse(UserObject);
    setResult(UserObject);

    // Check and manage login modal display
    if (!userLoggedIn) {
      let landingCount = localStorage.getItem("landingCount") || 0;
      console.log("landingCount", landingCount);
      landingCount = parseInt(landingCount, 10) + 1;

      if (landingCount === 1 || landingCount === 3) {
        setShowLoginModal(true);
        if (landingCount >= 3) {
          landingCount = 0;
        }
      }

      setShowLoginModal(false); //REMOVE WHEN WE GO LIVE

      localStorage.setItem("landingCount", landingCount);
    }

    const fetchAggregatedResults = async () => {
      try {
        const response = await fetch(
          "https://aggregateuserentries-o72kqu6ifa-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              questions,
              puzzle_id: UserObject.puzzle_id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setAggregatedResults(data);
      } catch (error) {
        console.error("Error fetching aggregated results:", error);
      }
    };

    if (UserObject && UserObject.puzzle_id) {
      fetchAggregatedResults();
    }
  }, [questions, userLoggedIn]);

  const copyToClipboard = () => {
    const scorePercentage = Math.round(
      (result?.currentScore / (questions.length * 3)) * 100
    );

    const formattedText =
      "Fragments of Memory\n" +
      "Score: " +
      scorePercentage +
      "%\n" +
      evaluateAnswers(result?.gameState, passedQuestions) +
      "\nTry your turn at https://FragmentsOfMemory.com";

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        toast.success("Results copied to clipboard!");
        logEvent(analytics, "share", {
          score: scorePercentage,
        });
      })
      .catch((err) => {
        toast.error("Failed to copy: ", err);
      });
  };

  const evaluateAnswers = (givenAnswers, questions) => {
    const maxLength = 3;
    const resultLines = [];

    for (let position = 0; position < maxLength; position++) {
      const line = givenAnswers
        .map((answers, index) => {
          if (position < answers.length) {
            return answers[position] === questions[index].corr_ans
              ? "🟢"
              : "🔴";
          } else {
            return "⚪️";
          }
        })
        .join(" ");
      resultLines.push(line);
    }

    return resultLines.reverse().join("\n");
  };

  if (isLandscape && isMobile) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <img
          src={rotateImage}
          alt="Please rotate your phone"
          style={{ width: "150px", marginBottom: "20px" }}
        />
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Oh, we can't fit everything on the screen.<br></br>Please rotate your
          phone
        </p>
      </div>
    );
  }

  return (
    <div className="parent">
      <div className="congratulations-container">
        <p className="congratulation-header">
          {result?.currentScore === 0
            ? "Try Harder Next Time"
            : "Congratulations!"}
        </p>
        <p>Your Statistics</p>
        <div className="stats">
          <div>
            <p className="large-text large-text-sm">
              {result?.gamesPlayed ? result?.gamesPlayed : 1}
            </p>
            <p className="small-text small-text-sm">Played</p>
          </div>
          <div>
            <p className="large-text large-text-sm">
              {(
                ((result?.currentScore / 3) * 3 * 100) /
                (3 * questions.length)
              ).toFixed(0)}
            </p>
            <p className="small-text small-text-sm">Score %</p>
          </div>
          <div>
            <p className="large-text large-text-sm">
              {result?.currentStreak ? result?.currentStreak : 0}
            </p>
            <p className="small-text small-text-sm">Current Streak</p>
          </div>
        </div>

        <div
          onClick={() => setCongratulationModal(!showCongratulationModal)}
          className="see-result-mainDiv"
        >
          <img src={graph} alt="graph" />
          <p className="see-result-mainP">
            See your results compared to everyone else!
          </p>
          <img style={{ marginLeft: "10px" }} src={aero} alt="arrow" />
        </div>

        {showCongratulationModal && (
          <CongratulationModal
            showCongratulationModal={showCongratulationModal}
            setCongratulationModal={setCongratulationModal}
            givenQuestions={questions}
            aggregatedResults={aggregatedResults}
          />
        )}

        <p
          onClick={() => setShowScoreModal(true)}
          className="!text-gray-400 text-sm cursor-pointer underline mt-4 mb-2"
        >
          How the score is calculated
        </p>

        <Modal
          isOpen={showScoreModal}
          onRequestClose={() => setShowScoreModal(false)}
          contentLabel="Score Calculation"
          className="score-modal-content"
          overlayClassName="score-modal-overlay"
        >
          <div className="score-modal-header">
            <IoClose
              onClick={() => setShowScoreModal(false)}
              className="modal-close"
              aria-label="Close modal"
            />
          </div>
          <div className="score-modal-body">
            <p className="text-xl mb-4 font-bold">
              The score is calculated based on your performance:
            </p>
            <ul className="modal-ul-bullets">
              <li>You start with 3 points for each question.</li>
              <li>
                If you answer correctly on the first try, you keep all 3 points.
              </li>
              <li>
                If you get it wrong, you lose 1 point for each wrong answer.
              </li>
              <li>
                After 3 wrong attempts, you get 0 points for that question.
              </li>
              <li>
                Your total score is the percentage of points you earned out of
                the maximum possible points.
              </li>
            </ul>
          </div>
        </Modal>

        <button onClick={copyToClipboard} className="share-button">
          Share <HiShare className="share-icon" />
        </button>

        <p className="text-sm mt-12">See you tomorrow for a new puzzle!</p>

        {/* Login Modal */}
        <Modal
          isOpen={showLoginModal}
          onRequestClose={() => setShowLoginModal(false)}
          contentLabel="Login Reminder"
          className="score-modal-content"
          overlayClassName="score-modal-overlay"
        >
          <div className="login-modal-body" style={{ textAlign: "center" }}>
            <IoClose
              onClick={() => setShowLoginModal(false)}
              className="modal-close"
              aria-label="Close modal"
              style={{ fontSize: "30px" }}
            />
            <div style={{ marginTop: "35px", marginBottom: "20px" }}>
              {/* Add the centered image here */}
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <img
                  src={choicesImage}
                  className="modal-image"
                  alt="Answer Choices"
                  style={{
                    height: "130px",
                    width: "auto",
                    display: "block",
                    margin: "0 auto",
                    marginBottom: "20px",
                  }}
                />
              </div>

              <p className="">
                You can save your stats whenever you play, and you'll never lose
                a streak again!
              </p>
              <button
                onClick={() => (window.location.href = "/login")}
                className="login-button"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "50px",
                  padding: "7px 30px",
                  marginTop: "20px",
                }}
              >
                Log in or create an account
              </button>
            </div>
          </div>
        </Modal>

        {/* Add the red banner at the bottom with a hyperlink */}
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: "2px 0",
            fontSize: "16px",
            fontWeight: "bold",
            zIndex: 1000, // Ensure it's on top of other content
          }}
        >
          <a
            href="https://forms.gle/J9vVrFEZfEiuhoiQA"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "white", // Make the link text white to match the background
              textDecoration: "underline", // Underline the text to indicate it's a link
            }}
          >
            Please fill in this survey.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
